<template>

  <div id="home">
    <div class="graficos">
      <GraficosHome />
    </div>




    <div class="user-info"></div>

    <div class="home-links">
      <router-link :key="i" class="home-link" :to="{ name: route.name }" v-for="(route, i) in routes">
        <b-icon class="h1 mb-2" :icon="route.meta.icon"></b-icon>
        <span class="h3 mb-2">{{ route.meta.label }}</span>
      </router-link>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <div>
      <a href="https://drive.google.com/file/d/1FzbzgiqD5q3Z9Uq1RlLLH4gL-IBuNsIn/view?usp=sharing" target="_blank"
        download>
        <b-button variant="outline">
          <b-icon icon="download"></b-icon> MANUAL DO SISTEMA EMS  </b-button>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GraficosHome from '@/components/layout/GraficosHome'

export default {
  name: 'Home',
 
  components:{
    GraficosHome
  },
    methods:{
      
      redirect(){
        window.open("#")
      }
    },
    computed: {
    ...mapState('auth', ['user']),
    routes () {
      return this.$router.options.routes.filter(route => (
        route.meta && route.meta.showDashboard
      ))
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin flex-center($columns: false) {
 // display: flex;
  align-items: center;
 // justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}
#home {
  width: 100%;
  height: 70vh;

  @include flex-center(true);
  justify-content: space-around;

  .user-info {
    text-align: center;
  }

  .graficos{
    padding: 0 0 40px 0;
    margin: 30px 0px 0px;
    align-items: left;
  }
 
  .linkexterno{
    opacity: 0.6 !important;
  }

  .home-links {
    width: 100%;
    margin: 80px 0px 0px;
    @include flex-center();
    .home-link {
      height: 100%;
      flex: 0 0 25%;
      margin: 0px 15px 20px;
      padding: 50px 32px;
      transition: .4s;
      text-decoration: none;
      color: var(--bs-white);
      @include flex-center(true);
      &:hover {
        transform: scale(1.1);
        text-decoration: none;
        box-shadow: 0 10px 20px rgba(0,0,0,0.5);
      }
    }
    .home-link:first-child { background-color: var(--bs-blue) }
    .home-link:nth-child(2) { background-color: var(--bs-orange) }
    .home-link:nth-child(3) { background-color: var(--bs-green) }
      .home-link:nth-child(4) { background-color: var(--bs-yellow) }
       .home-link:nth-child(5) { background-color: var(--bs-red) }
  }
}
</style>