<template>
  <div>
    <div class="row">
            <div class="col-2"> 
                <b-card-group deck>
                  <b-card
                    border-variant="primary"
                    header="Asos Criadas Hoje"
                    header-bg-variant="primary"
                    header-text-variant="white"
                    align="center">
                    <b-card-text><b>{{ qtdasosCriadas }}</b></b-card-text>
                  </b-card>
               </b-card-group>
            </div>

            <div class="col-2"> 
                <b-card-group>
                  <b-card
                  border-variant="info"
                  header="Asos Editadas Hoje"
                  header-bg-variant="info"
                  header-text-variant="white"
                  align="center">
                  <b-card-text><b>{{ qtdExamesAsosExcluidas+qtdasosEditadas }}</b>&nbsp;&nbsp;&nbsp;
                          <router-link class="home-link" :to="{ name: 'listasoeditadas', query: {} }">
                    <b-icon icon="card-checklist"  cursor="pointer" title="Listar Asos Excluidas" variant="info" font-scale="1.2"></b-icon>
                  </router-link></b-card-text>
                  </b-card>
                </b-card-group>
            </div>
  
             <div class="col-2">
                  <b-card-group>
                    <b-card 
                    border-variant="danger" 
                    header="Asos Excluídas Hoje" 
                    header-bg-variant="danger"
                    header-text-variant="white"
                    align="center">
                      <b-card-text><b>{{ qtdasosExcluidas }}</b> &nbsp;&nbsp;&nbsp;
                        <router-link class="home-link" :to="{ name: 'listasoexcluidas', query: { } }">
                  <b-icon icon="card-checklist"  cursor="pointer" title="Listar Asos Excluidas" variant="danger" font-scale="1.2"></b-icon>
                </router-link>
                      
                      </b-card-text>
                    </b-card>
                 </b-card-group>
            </div>
    </div>
  </div>
</template>

<script>


import { http } from '@/plugins/axios'
import { mapState } from 'vuex'

export default {


  
  data() {

    return {
        examesAsosExcluidas:'',
        qtdExamesAsosExcluidas:0,
        asosEditadas:'',
        qtdasosEditadas:0,
        asosCriadas:'',
        qtdasosCriadas:0,
        asosExcluidas:'',
        qtdasosExcluidas:0
    }
  },

    computed: {
        ...mapState('auth', ['user']),
    },

  mounted() {
    this.buscaExamesExcluidas(),
    this.buscaAsosEditadas(),
    this.buscaAsosCriadas(),
    this.buscaAsosExcluidas()
  },

  methods: {

              async buscaExamesExcluidas() {

                //pesquisa se a empresa deste paciente possui convenio para exames
                await http.get('/examesaso/historico/exameasoexcluida').then(resp => {
                   this.examesAsosExcluidas = resp.data
                   this.qtdExamesAsosExcluidas = this.examesAsosExcluidas.length

               /*  contagem de array Json funcionando
                let counter = 0;
              for (let i = 0; i < this.examesAsosExcluidas.length; i++) {
                if (this.examesAsosExcluidas[i].aso_id != '0') counter++;
              }
                console.log(counter);
                */
                })
              },


          async buscaAsosEditadas() {

            //pesquisa se a empresa deste paciente possui convenio para exames
            await http.get('/aso/historico/asoseditadas').then(resp => {

                this.asosEditadas = resp.data 
                this.qtdasosEditadas = this.asosEditadas.length
              })
          },


      async buscaAsosCriadas() {

      //pesquisa se a empresa deste paciente possui convenio para exames
      await http.get('/aso/historico/asoscriadas').then(resp => {
        this.asosCriadas = resp.data
        this.qtdasosCriadas = this.asosCriadas.length

      })
    },

    async buscaAsosExcluidas() {

      //pesquisa se a empresa deste paciente possui convenio para exames
      await http.get('/aso/historico/asosexcluidas').then(resp => {
        this.asosExcluidas = resp.data
        this.qtdasosExcluidas = this.asosExcluidas.length
      })
    }

  }
}
</script>
<style scoped lang="scss">
</style>
